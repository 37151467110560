import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private dataSubject: ReplaySubject<string> = new ReplaySubject<string>(1);

    setData(data: string): void {
        this.dataSubject.next(data);
    }

    getData$(): Observable<string> {
        return this.dataSubject.asObservable();
    }
}